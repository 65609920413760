import { Injectable } from '@angular/core';
import { iif, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExamCandidateReadyStateIdentifer } from '../enum/exam-candidate-ready-state-identifer.enum';
import { CandidateExamSummaryDTO } from '../model/exam/candidate-exam-summary-dto';
import { CandidateQuestionDto } from '../model/exam/candidate-question-dto';
import { ExamPauseDTO } from '../model/exam/exam-pause-dto';
import { ExamLogDto } from '../model/exam/log/exam-log-dto';
import { SaveQuestionAnswersDTO } from '../model/exam/save-question-answers-dto';
import { CandidateExamStateDto } from '../model/exam/state/candidate-exam-state-dto';
import { QuestionAnswerStateDTO } from '../model/exam/state/question-answer-state-dto';
import { CandidateAutomarkInfoDto } from '../model/exam/candidate-automark-info-dto';
import { UploadDataDto } from '../model/exam/upload-data';
import { APIResponse } from '../model/request/APIResponse';
import { CandidateRequestService } from './request/candidate-request.service';
import { RequestService } from './request/request.service';

@Injectable({
	providedIn: 'root'
})
export class ExamService {

	constructor(private candidateRequestService: CandidateRequestService,
		private requestService: RequestService) { }

	public getExamState(): Observable<APIResponse<CandidateExamStateDto>> {
		return this.candidateRequestService.get<CandidateExamStateDto>(`candidate/exam/state`);
	}

	public getExamSummary(): Observable<APIResponse<CandidateExamSummaryDTO>> {
		return this.candidateRequestService.get<CandidateExamSummaryDTO>(`candidate/exam/summary`);
	}

	public startCandidateExam(): Observable<ExamCandidateReadyStateIdentifer> {
		return this.candidateRequestService.put<ExamCandidateReadyStateIdentifer>('candidate/exam/start', null)
			.pipe(map(x => x.responseData));
	}

	public endCandidateExam(autoEnded?: boolean): Observable<APIResponse<void>> {
		return this.candidateRequestService.put<void>(autoEnded ? 'candidate/exam/end?autoEnded=true' : 'candidate/exam/end', null);
	}

	public saveAnswers(questionAnswers: QuestionAnswerStateDTO[], candidateQuestion: CandidateQuestionDto): Observable<APIResponse<void>> {

		const sa = new SaveQuestionAnswersDTO();
		sa.candidateQuestion = candidateQuestion;
		sa.questionAnswers = questionAnswers;

		return this.candidateRequestService.post<void>('candidate/answers/save', sa);
	}

	public examHasStarted(): Observable<APIResponse<boolean>> {
		return this.candidateRequestService.get<boolean>('candidate/exam/hasstarted');
	}

	public synchonizeExamViaPin(uploadData: UploadDataDto, checksum: string, examLogs: ExamLogDto[], pin: string): Observable<APIResponse<void>> {
		return this.requestService.post('synchronize/exam', { uploadData, checksum, pin, examLogs, accessToken: null });
	}

	public synchonizeExamViaAccessToken(uploadData: UploadDataDto, checksum: string, examLogs: ExamLogDto[], accessToken: string): Observable<APIResponse<void>> {
		return this.requestService.post('synchronize/exam', { uploadData, checksum, accessToken, examLogs, pin: null });
	}

	public candidateIsReady(): Observable<boolean> {
		return this.candidateRequestService.get<boolean>('candidate/exam/ready')
			.pipe(map(x => x.responseData));
	}

	public changeName(learnerName: string): Observable<APIResponse<void>> {
		return this.candidateRequestService.post<void>('candidate/exam/changename', { learnerName });
	}

	public updateCurrentQuestionNumber(currentquestionNumber: number): Observable<APIResponse<void>> {
		return this.candidateRequestService.post(`candidate/exam/questionnumber/${currentquestionNumber}`, null);
	}

	public isPaused(): Observable<boolean> {
		return this.candidateRequestService.get<ExamPauseDTO>('candidate/exam/paused')
			.pipe(map((response: APIResponse<ExamPauseDTO>) => {
				return !!response.responseData;
			}));
	}

	public getCandidateAutomarkingInfo(): Observable<APIResponse<CandidateAutomarkInfoDto>> {
		return this.candidateRequestService.get<CandidateAutomarkInfoDto>('candidate/exam/automarkinfo');
	}

	public getCandidateId(): Observable<string> {
		return this.candidateRequestService.get<string>('candidate/id')
			.pipe(map((response: APIResponse<string>) => {
				return response.responseData;
			}));
	}
}
